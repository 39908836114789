import { FC } from 'react'

import Heading from '@/components/Heading'
import ScrollTable from '@/components/ScrollTable'
import { DataProp } from '@/components/ScrollTable/ScrollTableProps'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { ContrastBgSide } from '@/layouts'


interface ProgramProps extends DefaultComponentProps {
  heading: string
  summary: string
  table: DataProp
  notice: string
  id?: string
}

export const Program: FC<ProgramProps> = ({
  className = '',
  heading = '',
  summary = '',
  notice = '',
  table,
  ...rest
}) => (
  <ContrastBgSide>
    <div className={styles['section']}>
      <div className={styles['section__content']}>
        <Heading className={styles['section__heading']} level={2}>
          {heading}
        </Heading>
        <div
          className={styles['section__summary']}
          dangerouslySetInnerHTML={{ __html: summary }}
        />
        <div className={styles['section__scroll-table']}>
          <ScrollTable data={table} />
        </div>
        <div
          className={styles['section__notice']}
          dangerouslySetInnerHTML={{ __html: notice }}
        />
      </div>
    </div>
  </ContrastBgSide>
)
